export { merge as mergeDeep } from 'lodash'
export { dotGet, dotSet } from 'teible'

export const chunk = (arr, size, horizontal) => {
  const len = arr.length
  const result = []
  if (horizontal) {
    for (let i = 0; i < len; i++) {
      const c = i % size
      if (result[c]) {
        result[c].push(arr[i])
        continue
      }

      result[c] = [arr[i]]
    }

    return result
  }

  for (let i = 0; i < len; i += size) {
    result.push(arr.slice(i, i + size))
  }

  return result
}

export const statsSeriesTimeseries = (arr, categories = null, options = null) => {
  if (!arr || !arr.length) {
    return {}
  }

  let cs = []
  const xes = arr.map(tick => {
    Object.keys(tick.data).forEach(key => {
      if (cs.indexOf(key) === -1) {
        cs = cs.concat(key)
      }
    })
    return new Date(tick.time)
  })

  if (!categories) {
    categories = cs.filter(c => c !== '*')
  }

  const columns = categories.map(category => {
    return [category, ...arr.map(tick => {
      if (!tick.data[category]) {
        return 0
      }

      if (options && options.scale) {
        return options.scale(tick.data[category])
      }

      return tick.data[category]
    })]
  })

  if (options) {
    return {
      x: 'x',
      columns: [
        ['x', ...xes],
        ...columns
      ],
      ...options
    }
  }

  return {
    x: 'x',
    columns: [
      ['x', ...xes],
      ...columns
    ]
  }
}

export const statsSeriesTimeseriesPie = (arr, categories = null, options = null) => {
  if (!arr || !arr.length) {
    return {}
  }

  if (!categories) {
    categories = arr.reduce((acc, tick) => {
      Object.keys(tick.data).forEach(key => {
        if (acc.indexOf(key) > -1) {
          return
        }

        acc = acc.concat(key)
      })

      return acc
    }, [])
  }

  const columns = categories.map(category => {
    return [category, ...arr.map(tick => tick.data[category] || 0)]
  })

  if (options) {
    return {
      columns,
      ...options
    }
  }

  return {
    columns
  }
}

export const statsSeriesPie = (obj, categories = null) => {
  if (!obj) {
    return {}
  }

  if (!categories) {
    categories = Object.keys(obj)
  }

  const columns = categories.map(category => {
    return [category, obj[category] || 0]
  })

  return {
    columns
  }
}

export const statsSeriesTimeseriesBar = (arr, categories = null, title = 'Data', options = null) => {
  if (!arr || !arr.length) {
    return {}
  }

  if (!categories) {
    categories = arr.reduce((acc, tick) => {
      Object.keys(tick.data).forEach(key => {
        if (acc.indexOf(key) > -1) {
          return
        }

        acc = acc.concat(key)
      })

      return acc
    }, [])
  }

  const columns = categories.map(category => {
    const count = arr.reduce((acc, val) => {
      acc += (val.data[category] || 0)
      return acc
    }, 0)

    if (!count) {
      return 0
    }

    if (options && options.scale) {
      return options.scale(count)
    }

    return count
  })

  const indices = Array.from(columns.keys())
  indices.sort((a, b) => columns[a] > columns[b] ? -1 : 1)

  const categoriesSorted = indices.map(i => categories[i])
  const columnsSorted = indices.map(i => columns[i])

  return {
    x: 'x',
    columns: [
      ['x', ...categoriesSorted],
      [title, ...columnsSorted]
    ]
  }
}

export const statsSeriesBar = (obj, categories = null, title = 'Data', options = null) => {
  if (!obj) {
    return {}
  }

  if (!categories) {
    categories = Object.keys(obj)
  }

  const columns = categories.map(category => {
    if (!obj[category]) {
      return 0
    }

    return obj[category]
  })

  if (!options.nested) {
    const indices = Array.from(columns.keys())
    indices.sort((a, b) => columns[a] > columns[b] ? -1 : 1)
    const categoriesSorted = indices.map(i => categories[i])
    const columnsSorted = indices.map(i => options.scale ? options.scale(columns[i]) : columns[i])
    return {
      x: 'x',
      columns: [
        ['x', ...categoriesSorted],
        [title, ...columnsSorted]
      ]
    }
  }

  if (!columns.length) {
    return {}
  }

  const fields = Object.keys(columns[0])
  const columnsFinal = fields.map(k => {
    return [k, ...columns.map(e => options.scale ? options.scale(e[k]) : e[k])]
  })

  return {
    x: 'x',
    columns: [
      ['x', ...categories],
      ...columnsFinal
    ]
  }
}

export const sensorSeriesGauge = (obj, categories = null) => {
  if (!obj) {
    return {}
  }

  if (!categories) {
    categories = Object.keys(obj)
  }

  const columns = categories.map(category => {
    return [category, obj[category] || 0]
  })

  return {
    columns
  }
}

export const bandwidthHuman = (bps = 0) => {
  const delta = 1000
  if (bps < delta) {
    return bps + ' bps'
  }

  let i = -1
  const units = [
    ' Kbps',
    ' Mbps',
    ' Gbps',
    ' Tbps'
  ]

  do {
    bps = bps / delta
    i++
  } while (bps > delta)

  return Number(bps).toFixed(1) + units[i]
}

export const formatTraffic = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
